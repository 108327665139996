<template>
    <div>
      <!-- begin:: Header -->
      <div
        id="kt_header"
        ref="kt_header"
        class="header"
        v-bind:class="headerClasses"
      >
        <div
          class="container-fluid d-flex align-items-center justify-content-between"
        >
          <!-- begin:: Header Menu -->
          <div
            class="header-menu-wrapper header-menu-wrapper-left"
            ref="kt_header_menu_wrapper"
          >
            <div
              v-if="headerMenuEnabled"
              id="kt_header_menu"
              ref="kt_header_menu"
              class="header-menu header-menu-mobile"
              v-bind:class="headerMenuClasses"
            >
              <!-- example static menu here -->
              <ul
                class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
              >
                <template>
                  <li class="breadcrumb-item">
                    <span style="font-size: 12px;">
                      OPERASIONAL / FORMULIS PENGAJUAN / <span style="font-weight: 600;">KEGIATAN LAPANGAN</span>
                    </span>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <!-- end:: Header Menu -->
          <!-- begin:: Header Topbar -->
          <div class="topbar">
            <!--begin: User Bar -->
            <div class="topbar-item">
              <!--begin: Notifications -->
              <ApprovalNotification></ApprovalNotification>
              <!--end: Notifications -->
              <div
                class="btn btn-icon w-auto d-flex align-items-center btn-lg px-2"
                id="kt_quick_user_toggle"
              >
                <span
                  class="symbol symbol-30 symbol-circle symbol-xl-30 pr-4 symbol-light-success"
                >
                  <img v-if="false" alt="Profile Photo" :src="currentUserPhoto" />
                  <span
                    v-if="true"
                    class="symbol-label font-size-h5 font-weight-bold"
                  >
                    {{ user_fullname.charAt(0).toUpperCase() }}
                  </span>
                </span>
                <span
                  class="d-none d-md-inline mr-12"
                  v-bind:style="{
                    'font-family': 'lato',
                    'font-size': '12px',
                    'font-weight': '600'
                  }"
                >
                  <span class="font-weight-bold font-size-base d-md-inline mr-1">
                    HI,
                  </span>
                  {{ user_fullname }}
                </span>
                <i class="ki ki-arrow-down icon-xs text-muted mr-12"></i>
              </div>
  
              <div
                id="kt_quick_user"
                ref="kt_quick_user"
                class="offcanvas offcanvas-right p-10"
              >
                <!--begin::Header-->
                <div
                  class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
                >
                  <h3 class="font-weight-bold m-0">
                    PROFIL USER
                  </h3>
                  <a
                    href="#"
                    class="btn btn-xs btn-icon btn-light btn-hover-primary"
                    id="kt_quick_user_close"
                  >
                    <i class="ki ki-close icon-xs text-muted"></i>
                  </a>
                </div>
                <!--end::Header-->
  
                <!--begin::Content-->
                <perfect-scrollbar
                  class="offcanvas-content pr-5 mr-n5 scroll"
                  style="max-height: 90vh; position: relative;"
                >
                  <!--begin::Header-->
                  <div class="d-flex align-items-center mt-5">
                    <div class="symbol symbol-100 mr-5">
                      <img :src="currentUserPersonalInfo.photo" alt="" />
                      <i class="symbol-badge bg-success"></i>
                    </div>
                    <div class="d-flex flex-column">
                      <span class="font-weight-bold font-size-h5 text-dark-75">
                        {{ user_fullname }}
                      </span>
                      <div class="navi mt-2">
                        <span class="navi-link p-0 pb-2">
                          <span class="navi-icon mr-1">
                            <span class="svg-icon svg-icon-lg svg-icon-primary">
                              <!--begin::Svg Icon-->
                              <inline-svg src="assets/icons/Mail.svg" />
                              <!--end::Svg Icon-->
                            </span>
                          </span>
                          <span class="navi-text text-muted">
                            {{ email }}
                          </span>
                        </span>
                      </div>
                      <button
                        class="btn btn-light-primary btn-bold"
                        @click="onLogout"
                      >
                        SIGN OUT
                      </button>
                    </div>
                  </div>
                  <!--end::Header-->
                  <div class="separator separator-dashed mt-8 mb-5"></div>
                  <div class="separator separator-dashed my-7"></div>
                </perfect-scrollbar>
                <!--end::Content-->
              </div>
            </div>
            <!--end: User Bar -->
          </div>
          <!-- end:: Header Topbar -->
        </div>
      </div>
      <!-- end:: Header -->
      <!-- Body -->
      <div style="margin-bottom: 43px;">
        <div>
          <img class="mb-4" src="assets/background_page_title.png" alt="" style="height: 36px; margin-right: 10px;">
          <span style="font-size: 30px; font-weight: 700;">
            KEGIATAN LAPANGAN
          </span>
        </div>
        <div>
          <span>
            MENAMPILKAN DAN MEMBUAT DATA TRANSAKSI KEGIATAN LAPANGAN BARU
          </span>
        </div>
      </div>
      <div class="form-group">
        <template>
          <v-toolbar flat>
            <v-dialog v-model="dialog" max-width="800px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="white"
                  light
                  class="mb-12"
                  v-bind="attrs"
                  v-on="on"
                  style="text-transform: capitalize !important; min-height: 48px;"
                >
                  BUAT FORM BARU +
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
  
                <v-card-text>
                  <v-container>
                    <v-col>
                      <v-row>
                        <v-col cols="3" class="mt-4">
                          <span style="color: black; font-size: 14px;"
                            >PERUSAHAAN<span style="color: red;">*</span></span
                          >
                        </v-col>
                        <v-col cols="9">
                          <v-select
                            v-model="formInput.workgroup_id"
                            id="workgroup_id"
                            :items="data_workgroup_combobox"
                            @click="dataWorkgroup_Combobox"
                            item-value="workgroup_id"
                            item-text="workgroup_name"
                            class="vuetify-custom"
                            solo
                            v-bind:style="{
                              color: '#1e2758 !important'
                            }"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3" class="mt-4">
                          <span style="color: black; font-size: 14px;"
                            >TANGGAL TRANSAKSI<span style="color: red;"
                              >*</span
                            ></span
                          >
                        </v-col>
                        <v-col cols="9">
                          <v-menu
                            ref="date_field_activities"
                            v-model="date_field_activities"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            full-width
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="formInputNotClear.date_submission"
                                class="vuetify-custom"
                                solo
                                v-bind:style="{
                                  color: '#c30e0e !important'
                                }"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="date_submission"
                              no-title
                              @input="date_field_activities = false"
                              @change="onChangeDate(date_submission)"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3" class="mt-4">
                          <span style="color: black; font-size: 14px;"
                            >UNIT MATRA<span style="color: red;">*</span></span
                          >
                        </v-col>
                        <v-col cols="9">
                          <v-select
                            v-model="formInput.organizational_unit_id"
                            id="organizational_unit_id"
                            :items="data_organizational_units_combobox"
                            @click="dataOrganizationalUnits_Combobox"
                            item-value="organizational_unit_id"
                            item-text="organizational_unit_name"
                            class="vuetify-custom"
                            solo
                            v-bind:style="{
                              color: '#1e2758 !important'
                            }"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3" class="mt-4">
                          <span style="color: black; font-size: 14px;">
                            SUB UNIT MATRA<span style="color: red;">*</span>
                          </span>
                        </v-col>
                        <v-col cols="9">
                          <v-select
                            v-model="formInput.sub_organizational_unit"
                            id="sub_organizational_unit_id"
                            :items="data_sub_organizational_unit_combobox"
                            @change="
                              titleFromSubOrganizationalUnit(
                                formInput.sub_organizational_unit
                              )
                            "
                            item-value="sub_organizational_unit_id"
                            item-text="sub_organizational_unit_name"
                            class="vuetify-custom"
                            solo
                            v-bind:style="{ color: '#1e2758 !important' }"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <div v-if="seenComboboxWorkUnit == 1">
                        <v-row>
                          <v-col cols="3" class="mt-4">
                            <span style="color: black; font-size: 14px;"
                              >{{ title_select
                              }}<span style="color: red;">*</span></span
                            >
                          </v-col>
                          <v-col cols="9">
                            <v-select
                              v-model="formInput.work_unit_id"
                              id="work_unit_id"
                              :items="data_work_units_combobox"
                              @click="dataWorkUnits_Combobox"
                              @change="itemWorkUnits(formInput.work_unit_id)"
                              item-value="work_unit_id"
                              item-text="work_unit_name"
                              class="vuetify-custom"
                              solo
                              v-bind:style="{
                                color: '#1e2758 !important'
                              }"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </div>
                      <v-row>
                        <v-col cols="3" class="mt-4">
                          <span style="color: black; font-size: 14px;"
                            >PROYEK<span style="color: red;">*</span></span
                          >
                        </v-col>
                        <v-col cols="9">
                          <v-select
                            v-model="formInput.project_id"
                            id="project_id"
                            :items="data_project_combobox"
                            @click="dataProject_Combobox"
                            item-value="project_id"
                            item-text="project_name"
                            class="vuetify-custom"
                            solo
                            v-bind:style="{
                              color: '#1e2758 !important'
                            }"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;">DESKRIPSI TRANSAKSI</span>
                        </v-col>
                        <v-col cols="9">
                          <v-textarea
                            v-model="formInput.submission_desc"
                            class="vuetify-custom"
                            solo
                            v-bind:style="{
                              color: '#1e2758 !important'
                            }"
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                      <!-- <v-row>
                        <v-col cols="3" class="mt-4">
                          <span style="color: black; font-size: 14px;">
                            Status Pembayaran<span style="color: red;">*</span>
                          </span>
                        </v-col>
                        <v-col cols="9">
                          <v-select
                            v-model="formInput.payment_status"
                            id="payment_status_id"
                            :items="data_payment_status_combobox"
                            @change="seenDetailPaymentStatus"
                            item-value="payment_status_id"
                            item-text="payment_status_name"
                            class="vuetify-custom"
                            solo
                            v-bind:style="{ color: '#1e2758 !important' }"
                          ></v-select>
                          <v-row v-if="seen === 'Lain-lain'">
                            <v-col cols="9">
                              <v-text-field
                                v-model="formInputPaymentStatus.payment_status"
                                class="vuetify-custom"
                                solo
                                v-bind:style="{
                                  color: '#1e2758 !important'
                                }"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row v-else>
                            <v-col cols="9">
                              <v-text-field
                                v-model="formInputPaymentStatus.payment_status"
                                class="vuetify-custom"
                                solo
                                v-bind:style="{
                                  color: '#1e2758 !important',
                                  visibility: 'hidden',
                                  position: 'absolute'
                                }"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row> -->
                      <v-row>
                        <v-col cols="3" class="mt-4">
                          <span style="color: black; font-size: 14px;"
                            >UPLOAD BUKTI PERINTAH<span style="color: red;"
                              >*</span
                            ></span
                          >
                        </v-col>
                        <v-col cols="6">
                          <v-file-input
                            accept="image/*,application/pdf"
                            type="file"
                            @change="onImageChange"
                            v-model="formInput.path_image_order_proof"
                            show-size
                            counter
                            :clearable="false"
                          ></v-file-input>
                          <v-div
                            v-if="
                              this.add_field_activities.path_image_order_proof2 !=
                                null
                            "
                          >
                            <v-img
                              :src="add_field_activities.path_image_order_proof2"
                            ></v-img>
                          </v-div>
                          <v-div v-else>
                            <v-img
                              :src="editedItem.path_image_order_proof"
                            ></v-img>
                          </v-div>
                        </v-col>
                      </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;">
                          AJUKAN KE<span style="color: red;">*</span>
                        </span>
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.cashbank_type"
                          id="cashbank_type_id"
                          :items="data_cashbank_type_combobox"
                          item-value="cashbank_type_id"
                          item-text="cashbank_type_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{ color: '#1e2758 !important' }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    </v-col>
                  </v-container>
                </v-card-text>
  
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    class="mb-4 btn btn-outline-secondary"
                    @click="close"
                  >
                    BATALKAN
                  </v-btn>
                  <v-btn
                    color="primary"
                    light
                    class="mb-4"
                    @click="formSubmit"
                    style="text-transform: capitalize !important; color: white !important;"
                  >
                    SIMPAN FORM BARU
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogSubmissionInfo" max-width="800px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
  
                <v-card-text>
                  <v-container>
                    <v-col>
                      <v-row>
                        <v-col cols="3" class="mt-4">
                          <span style="color: black; font-size: 14px;"
                            >INFORMASI<span style="color: red;">*</span></span
                          >
                        </v-col>
                        <v-col cols="9">
                          <v-textarea
                            v-model="formInput.submission_info"
                            class="vuetify-custom"
                            solo
                            v-bind:style="{
                              color: '#1e2758 !important'
                            }"
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-container>
                </v-card-text>
  
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    class="mb-4 btn btn-outline-secondary"
                    @click="closeSubmissionInfo"
                  >
                    BATALKAN
                  </v-btn>
                  <v-btn
                    color="primary"
                    light
                    class="mb-4"
                    @click="formSubmit"
                    style="text-transform: capitalize !important; color: white !important;"
                  >
                    SIMPAN
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Cari Form Pengajuan"
              solo
              hide-details
              class="mb-12"
              style="width: 10px; box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);"
            ></v-text-field>
            <v-dialog v-model="dialogShowActivityInvoicesTable" max-width="1000px">
              <v-card>
                <v-card-title>
                  <span>DATA KEGIATAN</span>
                </v-card-title>
                <v-card-text>
                  <div class="form-group">
                    <v-toolbar flat class="mt-6">
                      <v-dialog v-model="dialogCreateActivityInvoices" max-width="800px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="white" light class="mb-12" v-bind="attrs" v-on="on" style="text-transform: capitalize !important; min-height: 48px;" @click="showDialogcreateDataActivityInvoices">
                            BUAT DATA BARU +
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                          </v-card-title>
  
                          <v-card-text>
                            <v-container>
                              <v-col>
                                <v-row>
                                  <v-col cols="3" class="mt-4">
                                    <span style="color: black; font-size: 14px;"
                                      >NAMA<span style="color: red;"
                                        >*</span
                                      ></span
                                    >
                                  </v-col>
                                  <v-col cols="9">
                                    <v-text-field
                                      v-model="formInput.activity_invoice_name"
                                      class="vuetify-custom"
                                      solo
                                      v-bind:style="{
                                        color: '#c30e0e !important'
                                      }"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="3" class="mt-8">
                                    <span style="color: black; font-size: 14px;"
                                      >NOMINAL<span style="color: red;"
                                        >*</span
                                      ></span
                                    >
                                  </v-col>
                                  <v-col cols="9">
                                    <vuetify-money
                                      v-model="formInput.activity_invoice_amount"
                                      v-bind:placeholder="placeholder"
                                      v-bind:readonly="readonly"
                                      v-bind:disabled="disabled"
                                      v-bind:outlined="outlined"
                                      v-bind:clearable="clearable"
                                      v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                                      v-bind:options="option"
                                      class="vuetify-custom"
                                      solo
                                      v-bind:style="{
                                        color: '#1e2758 !important'
                                      }"
                                    />
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="3" class="mt-8">
                                    <span style="color: black; font-size: 14px;"
                                      >KETERANGAN<span style="color: red;"
                                        >*</span
                                      ></span
                                    >
                                  </v-col>
                                  <v-col cols="9">
                                    <v-textarea
                                      v-model="formInput.activity_invoice_desc"
                                      class="vuetify-custom"
                                      solo
                                      v-bind:style="{
                                        color: '#1e2758 !important'
                                      }"
                                    >
                                    </v-textarea>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-container>
                          </v-card-text>
  
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              class="mb-4 btn btn-outline-secondary"
                              @click="closeActivityInvoices"
                            >
                              BATALKAN
                            </v-btn>
                            <v-btn
                              color="primary"
                              light
                              class="mb-4"
                              @click="formSubmit"
                              style="text-transform: capitalize !important; color: white !important;"
                            >
                              SIMPAN KEGIATAN BARU
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model="searchActivityInvoices"
                        prepend-inner-icon="mdi-magnify"
                        label="CARI KEGIATAN"
                        solo
                        hide-details
                        class="mb-12"
                        style="width: 10px; box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);"
                      ></v-text-field>
                      <v-dialog v-model="dialogUploadBill" max-width="800px">
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-col>
                                <v-row>
                                  <v-col cols="3" class="mt-4">
                                    <span style="color: black; font-size: 14px;">
                                      UPLOAD BON<span style="color: red;">*</span>
                                    </span>
                                  </v-col>
                                  <v-col cols="9">
                                    <v-file-input
                                      accept="image/*,application/pdf"
                                      type="file"
                                      v-model="formInput.path_file_name"
                                      multiple
                                      show-size
                                      counter
                                      :clearable="false"
                                    ></v-file-input>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogUploadBill = false">
                              TUTUP
                            </v-btn>
                            <v-btn
                              color="primary"
                              light
                              @click="formSubmit"
                              style="text-transform: capitalize !important; color: white !important;"
                            >
                              SIMPAN
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="dialogSeenBill" max-width="800px">
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-col>
                                <div>
                                  <span class="text-h6">FILE FOTO</span>
                                </div>
                                <v-col class="text-center" v-for="(data) in data_files" :key="data.cashbank_id">
                                  <div v-if="data.path_file_extention == 'png' || data.path_file_extention == 'jpeg' || data.path_file_extention == 'jpg' || data.path_file_extention == 'gif'">
                                    <img :src="data.path_file_name" alt="Foto" style="max-width: 700px;" />
                                    <v-btn
                                        color="red"
                                        light
                                        small
                                        class="mt-2"
                                        @click="deleteDataBill(data.path_file_id)"
                                        data-toggle="tooltip"
                                        title="HAPUS"
                                        v-if="user_role.toLowerCase() === 'operasional' || user_role.toLowerCase() === 'atasan operasional'"
                                      >
                                        <span small style="color: white;">HAPUS</span>
                                      </v-btn>
                                  </div>
                                </v-col>
                                <div class="mt-12">
                                  <span class="text-h6">FILE PDF</span>
                                </div>
                                <v-col v-for="(data) in data_files" :key="data.cashbank_id">
                                  <div v-if="data.path_file_extention == 'pdf'">
                                    <a :href="data.path_file_name" target="_blank">LINK FILE PDF</a>
                                    <v-btn
                                        color="red"
                                        light
                                        small
                                        class="ml-4"
                                        @click="deleteDataBill(data.path_file_id)"
                                        data-toggle="tooltip"
                                        title="HAPUS"
                                        v-if="user_role.toLowerCase() === 'operasional' || user_role.toLowerCase() === 'atasan operasional'"
                                      >
                                        <span small style="color: white;">HAPUS</span>
                                      </v-btn>
                                  </div>
                                </v-col>
                              </v-col>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogSeenBill = false">
                              TUTUP
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="dialogDeleteActivityInvoices" max-width="500px">
                        <v-card>
                          <v-card-title class="text-h5"
                            >APAKAH YAKIN UNTUK MENGHAPUS DATA INI?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="closeDeleteActivityInvoices"
                              >BATAL</v-btn
                            >
                            <v-btn
                              text
                              @click="deleteDataActivityInvoices"
                              v-bind:style="{
                                color: '#1e2758 !important'
                              }"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      :headers="headers_activity_invoices"
                      :items="data_activity_invoices"
                      item-key="activity_invoice_id"
                      :options.sync="options"
                      :search="searchActivityInvoices"
                      class="elevation-1"
                      :loading="loadingTable"
                      loading-text="HARAP TUNGGU..."
                    >
                      <template
                        v-slot:[`item.data-table-select`]="{
                          item,
                          isSelected,
                          select
                        }"
                      >
                        <div
                          v-if="item.paid_status == 0 || item.paid_status == 1"
                        >
                          <div
                            v-if="item.paid_status != 0 && item.paid_status > 0"
                          >
                            <v-simple-checkbox
                              :value="true"
                              :readonly="item.paid_status"
                              :disabled="item.paid_status"
                              @input="select($event)"
                            ></v-simple-checkbox>
                          </div>
                          <div v-else>
                            <v-simple-checkbox
                              :value="isSelected"
                              :readonly="item.paid_status"
                              :disabled="item.paid_status"
                              @input="select($event)"
                            ></v-simple-checkbox>
                          </div>
                        </div>
                        <v-icon small color="black" v-else>
                          flaticon2-line
                        </v-icon>
                      </template>
                      <template v-slot:[`item.number`]="{ index }">
                        <td>
                          {{
                            options.page * options.itemsPerPage -
                              options.itemsPerPage +
                              (index + 1)
                          }}
                        </td>
                      </template>
                      <template v-slot:[`item.activity_invoice_amount`]="{ item }">
                        <td>Rp {{ formatNumber(item.activity_invoice_amount) }}</td>
                      </template>
                      <template v-slot:[`item.bill_file`]="{ item }">
                        <v-btn
                          color="blue"
                          light
                          small
                          class="mr-3"
                          @click="seenFileBill(item)"
                          data-toggle="tooltip"
                          title="LIHAT"
                          style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                        >
                          <v-icon small color="white">
                              mdi-eye
                            </v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.aksi`]="{ item }">
                        <div>
                          <v-btn
                            color="blue"
                            light
                            small
                            class="mr-3 my-2"
                            @click="uploadFileBill(item)"
                            data-toggle="tooltip"
                            title="UPLOAD BON"
                            style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                            v-if="item.approval_status == 0"
                          >
                            <v-span small style="color: white;">
                              UPLOAD BON
                            </v-span>
                          </v-btn>
                          <v-btn
                            color="green"
                            light
                            small
                            class="mr-3"
                            @click="editItemActivityInvoices(item)"
                            data-toggle="tooltip"
                            title="EDIT"
                            style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                            v-if="item.approval_status == 0"
                          >
                            <v-icon small color="white">
                              mdi-pencil
                            </v-icon>
                          </v-btn>
                          <v-btn
                            color="red"
                            light
                            small
                            @click="deleteItemActivityInvoices(item)"
                            data-toggle="tooltip"
                            title="HAPUS"
                            style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                            v-if="item.approval_status == 0"
                          >
                            <v-icon small color="white">
                              mdi-delete
                            </v-icon>
                          </v-btn>
                          <v-icon small color="black" v-if="item.approval_status == 1">
                            flaticon2-line
                          </v-icon>
                        </div>
                      </template>
                    </v-data-table>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" text @click="showHistoryActivityInvoices(false)">
                    Tutup
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogSeenFile" max-width="800px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
  
                <v-card-text>
                  <v-container>
                    <v-col v-for="(data) in data_files" :key="data.submission_number">
                      <div v-if="data.path_image_order_proof_extention == 'png' || data.path_image_order_proof_extention == 'jpeg' || data.path_image_order_proof_extention == 'jpg' || data.path_image_order_proof_extention == 'gif'">
                        <div>
                          <span class="text-h6">FILE FOTO</span>
                        </div>
                        <div class="text-center">
                          <img :src="data.path_image_order_proof" alt="Foto" style="max-width: 700px;" />
                        </div>
                      </div>
                      <div v-else-if="data.path_image_order_proof_extention == 'pdf'">
                        <div>
                          <span class="text-h6">FILE PDF</span>
                        </div>
                        <div>
                          <a :href="data.path_image_order_proof" target="_blank">LINK FILE PDF</a>
                        </div>
                      </div>
                    </v-col>
                  </v-container>
                </v-card-text>
  
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialogSeenFile = false">
                    TUTUP
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >APAKAH YAKIN UNTUK MENGHAPUS DATA INI?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="closeDelete">BATAL</v-btn>
                  <v-btn
                    text
                    @click="deleteDataFieldActivities"
                    v-bind:style="{
                      color: '#1e2758 !important'
                    }"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="data_field_activities"
            :item-key="submission_number"
            :options.sync="options"
            :search="search"
            sort-by="Id"
            class="elevation-1 mb-12"
            :item-class="row_bg_color"
            :loading="loadingTable"
            loading-text="HARAP TUNGGU..."
          >
            <template v-slot:[`item.number`]="{ index }">
              <td>
                {{
                  options.page * options.itemsPerPage -
                    options.itemsPerPage +
                    (index + 1)
                }}
              </td>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <td>Rp {{ formatNumber(item.amount) }}</td>
            </template>
            <!-- <template v-slot:[`item.payment_status`]="{ item }">
              <td>{{ item.payment_status }}</td>
            </template> -->
            <template v-slot:[`item.money_status`]="{ item }">
              <td v-if="item.money_status == 'S'">
                Pengajuan
              </td>
              <td v-else-if="item.money_status == 'HO'">
                Uang Diserahkan
              </td>
              <td v-else-if="item.money_status == 'R'">
                Uang Dikembalikan
              </td>
              <td v-else-if="item.money_status == ' '">
                -
              </td>
            </template>
            <template v-slot:[`item.created_by`]="{ item }">
              <td>
                {{ item.created_by == "" ? "-" : item.created_by }}
              </td>
            </template>
            <template v-slot:[`item.date_created`]="{ item }">
              <td>
                {{ item.date_created == null ? "-" : item.date_created }}
              </td>
            </template>
            <template v-slot:[`item.checking_by`]="{ item }">
              <td>
                {{ item.checking_by == "" ? "-" : item.checking_by }}
              </td>
            </template>
            <template v-slot:[`item.date_checking`]="{ item }">
              <td>
                {{ item.date_checking == null ? "-" : item.date_checking }}
              </td>
            </template>
            <template v-slot:[`item.approval_by`]="{ item }">
              <td>
                {{ item.approval_by == "" ? "-" : item.approval_by }}
              </td>
            </template>
            <template v-slot:[`item.date_approval`]="{ item }">
              <td>
                {{ item.date_approval == null ? "-" : item.date_approval }}
              </td>
            </template>
            <template v-slot:[`item.order_proof`]="{ item }">
              <v-btn
                color="blue"
                light
                small
                class="mr-3"
                @click="seenFile(item)"
                data-toggle="tooltip"
                title="LIHAT"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
              >
                <v-icon small color="white">
                    mdi-eye
                  </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.submission_info`]="{ item }">
              <tr
                @mouseover="editedIndex = data_field_activities.index"
                @mouseleave="editedIndex = -4"
              >
                <td>
                  <div class="d-flex align-items-center position-relative">
                    <v-btn
                      color="blue"
                      light
                      small
                      class="mx-auto position-absolute"
                      @click="editItemSubmissionInfo(item)"
                      data-toggle="tooltip"
                      title="EDIT INFO"
                      v-show="data_field_activities.index == editedIndex"
                      v-if="user_role.toLowerCase() === 'operasional' && item.checking_status === 0"
                    >
                      <span small style="color: white;">EDIT</span>
                    </v-btn>
                    <p class="my-2 mx-auto">{{ item.submission_info }}</p>
                  </div>
                </td>
              </tr>
            </template>
            <template v-slot:[`item.aksi`]="{ item }">
              <div v-if="user_role.toLowerCase() === 'atasan'">
                <v-btn
                  color="blue"
                  light
                  small
                  class="mr-3 my-2"
                  @click="activityInvoicesSelect(item)"
                  data-toggle="tooltip"
                  title="LIHAT KASBON"
                  style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                >
                  <v-span small style="color: white;">
                    LIHAT KASBON
                  </v-span>
                </v-btn>
              </div>
              <div v-else-if="user_role.toLowerCase() === 'atasan operasional'">
                <v-btn
                  color="blue"
                  light
                  small
                  class="mr-3 my-2"
                  @click="updateDataFieldActivitiesApproval(item)"
                  data-toggle="tooltip"
                  title="APPROV"
                  style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                  v-if="item.cash_submission_status == 1 && item.approval_status == 0"
                >
                  <v-span small style="color: white;">
                    APPROV
                  </v-span>
                </v-btn>
                <v-btn
                  color="blue"
                  light
                  small
                  class="mr-3 my-2"
                  @click="activityInvoicesSelect(item)"
                  data-toggle="tooltip"
                  title="LIHAT KASBON"
                  style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                >
                  <v-span small style="color: white;">
                    LIHAT KASBON
                  </v-span>
                </v-btn>
              </div>
              <div v-else>
                <div>
                  <v-btn
                    color="blue"
                    light
                    small
                    class="my-2"
                    @click="updateDataFieldActivitiesCashSubmissionStatus(item)"
                    data-toggle="tooltip"
                    title="PENGAJUAN SELESAI"
                    style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                    v-if="item.cash_submission_status == 0"
                  >
                    <v-span small style="color: white;">
                      PENGAJUAN SELESAI
                    </v-span>
                  </v-btn>
                </div>
                <v-btn
                  color="blue"
                  light
                  small
                  class="mr-3"
                  @click="activityInvoicesSelect(item)"
                  data-toggle="tooltip"
                  title="LIHAT KASBON"
                  style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                >
                  <v-span small style="color: white;">
                    LIHAT KASBON
                  </v-span>
                </v-btn>
                <v-btn
                  color="green"
                  light
                  small
                  class="mr-3"
                  @click="dataNotification(item)"
                  data-toggle="tooltip"
                  title="NOTIFIKASI"
                  style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                  v-if="item.money_status == 'C'"
                >
                  <v-span small style="color: white;">
                    NOTIFIKASI
                  </v-span>
                </v-btn>
                <v-btn
                  color="green"
                  light
                  small
                  class="mr-3"
                  @click="editItem(item)"
                  data-toggle="tooltip"
                  title="EDIT"
                  style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                  v-if="item.checking_status === 0 && item.approval_status === 0"
                >
                  <v-icon small color="white">
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn
                  color="red"
                  light
                  small
                  @click="deleteItem(item)"
                  data-toggle="tooltip"
                  title="HAPUS"
                  style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                  v-if="item.checking_status === 0 && item.approval_status === 0"
                >
                  <v-icon small color="white">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </template>
        <!-- PDF ALL Session -->
        <template>
          <div>
            <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="true"
              :paginate-elements-by-height="1400"
              :pdf-quality="2"
              :manual-pagination="false"
              :html-to-pdf-options="htmlToPdfAllOptions"
              pdf-content-width="100%"
              @hasStartedGeneration="hasStartedGeneration()"
              @hasGenerated="hasGenerated($event)"
              ref="html2PdfAll"
            >
              <section slot="pdf-content">
                <!-- PDF Content Here -->
                <section class="pdf-item">
                  <div align="left">
                    <p
                      style="
                            font-family: 'Verdana';
                            font-style: normal;
                            font-size: 16px;
                          "
                    >
                      RIWAYAT KASBON
                    </p>
                  </div>
                  <br />
                  <div align="left">
                    <table style="border-collapse: collapse; border-spacing: 0;">
                      <tr>
                        <td
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px; font-weight: 600; line-height: 14px; padding-right: 10px"
                        >
                          PROYEK:
                        </td>
                        <td
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px; font-weight: 600; line-height: 14px; padding-right: 10px"
                        >
                          {{ data_history_activity_invoices_print.project_name }}
                        </td>
                      </tr>
                    </table>
  
                    <table style="border-collapse: collapse; border-spacing: 0;">
                      <tr>
                        <td
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px; font-weight: 600; line-height: 14px; padding-right: 10px"
                        >
                          NOMINAL PROYEK:
                        </td>
                        <td
                          style="font-family: 'Verdana'; font-style: normal; font-size: 12px; font-weight: 600; line-height: 14px; padding-right: 10px"
                        >
                          RP {{ formatNumber(data_history_activity_invoices_print.project_amount) }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <br />
                  <div align="center">
                    <table border="1" width="100%">
                      <thead>
                        <tr align="center">
                          <th
                            scope="col"
                            style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                            width="5%"
                          >
                            NO
                          </th>
                          <th
                            scope="col"
                            style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                          >
                            NAMA
                          </th>
                          <th
                            scope="col"
                            style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                          >
                            JABATAN
                          </th>
                          <th
                            scope="col"
                            style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                          >
                            NOMINAL
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in data_history_activity_invoices"
                          :key="data.activity_invoice_id"
                          align="center"
                        >
                          <td
                            style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                          >
                            {{ index + 1 }}
                          </td>
                          <td
                            style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                          >
                            {{ data.activity_invoice_name }}
                          </td>
                          <td
                            style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                          >
                            {{ data.position_name }}
                          </td>
                          <td
                            style="font-family: 'Verdana'; font-style: normal; font-size: 12px;"
                          >
                            RP {{ formatNumber(data.amount) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                <div class="html2pdf__page-break" />
              </section>
            </vue-html2pdf>
          </div>
        </template>
        <!-- END PDF ALL Session -->
      </div>
      <!-- End Body -->
    </div>
  </template>
  
  <style lang="scss">
  .topbar {
    .dropdown-toggle {
      padding: 0;
      &:hover {
        text-decoration: none;
      }
  
      &.dropdown-toggle-no-caret {
        &:after {
          content: none;
        }
      }
    }
  
    .dropdown-menu {
      margin: 0;
      padding: 0;
      outline: none;
      .b-dropdown-text {
        padding: 0;
      }
    }
  }
  </style>
  
  <style lang="scss" scoped>
  #kt_quick_user {
    overflow: hidden;
  }
  </style>
  
  <style scoped>
  .vuetify-custom >>> .v-text-field__slot label {
    color: #1e2758 !important;
  }
  
  .vuetify-custom >>> .v-select__slot label {
    color: #1e2758 !important;
  }
  
  .vuetify-custom >>> .v-input__icon i {
    color: #1e2758 !important;
  }
  
  .position-relative:hover .position-absolute {
    top: auto;
  }
  </style>
  
  <style>
  .bg-green {
    background-color: rgba(0, 255, 0, 0.2);
  }
  
  .bg-red {
    background-color: rgba(255, 0, 0, 0.2);
  }
  
  .bg-yellow {
    background-color: rgba(255, 255, 0, 0.2);
  }
  
  .bg-blue {
    background-color: rgba(2, 118, 255, 0.2);
  }
  
  .bg-orange {
    background-color: rgba(255, 165, 0, 0.2);
  }
  </style>
  
  <script>
  import { mapGetters } from "vuex";
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  import KTLayoutHeader from "@/assets/js/layout/base/header.js";
  import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
  import { LOGOUT } from "@/core/services/store/auth.module";
  import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
  import KTOffcanvas from "@/assets/js/components/offcanvas.js";
  import ApprovalNotification from "@/view/pages/dashboard/ApprovalNotificationBell.vue";
  import localStorage from "@/core/services/store/localStorage";
  import Services from "@/core/services/app_internal/Services";
  import ApiService from "@/core/services/api.service";
  import VueHtml2pdf from "vue-html2pdf";
  import Swal from "sweetalert2";
  import "@/core/plugins/vuetify-money.js";
  import { formatNumber } from "@/helpers/helper.js";
  import { formatDate } from "@/helpers/helper.js";
  import axios from 'axios';
  
  export default {
    name: "submission-form",
  
    components: {
      ApprovalNotification,
      VueHtml2pdf
    },
  
    data: () => ({
      search: "",
      selected: [],
      disabledCount: 0,
      searchActivityInvoices: "",
      searchHistoryActivityInvoices: "",
      dialog: false,
      dialogSubmissionInfo: false,
      dialogCreateActivityInvoices: false,
      dialogDelete: false,
      dialogDeleteActivityInvoices: false,
      dialogShowActivityInvoicesTable: false,
      dialogShowHistoryActivityInvoicesTable: false,
      dialogMoneyStatus: false,
      dialogUploadBill: false,
      dialogSeenBill: false,
      dialogSeenFile: false,
      create_data_field_activities: [],
      update_data_field_activities: [],
      update_data_field_activities_amount: [],
      delete_data_field_activities: [],
      delete_data_files: [],
      create_data_activity_invoices: [],
      create_data_voucher: [],
      update_data_activity_invoices: [],
      delete_data_activity_invoices: [],
      data_field_activities: [],
      data_escrow_notification: [],
      data_bank_guarantee_notification: [],
      data_submission_form_finance_notification: [],
      data_activity_invoices: [],
      data_history_activity_invoices: [],
      data_workgroup_combobox: [],
      data_organizational_units_combobox: [],
      data_project_combobox: [],
      data_work_units_combobox: [],
      data_positions_combobox: [],
      data_sub_organizational_unit_combobox: [],
      data_files: [],
      data_transaction_type_combobox: [
        {
          transaction_type_id: "S",
          transaction_type_name: "SOSIALISASI"
        },
        {
          transaction_type_id: "PO",
          transaction_type_name: "PROYEK OPERASIONAL"
        }
      ],
      data_payment_status_combobox: [
        {
          payment_status_id: "100%",
          payment_status_name: "100%"
        },
        {
          payment_status_id: "80%",
          payment_status_name: "80%"
        },
        {
          payment_status_id: "20%",
          payment_status_name: "20%"
        },
        {
          payment_status_id: "Lain-lain",
          payment_status_name: "LAIN-LAIN"
        }
      ],
      data_money_status_combobox: [
        {
          money_status_id: "S",
          money_status_name: "PENGAJUAN"
        },
        {
          money_status_id: "HO",
          money_status_name: "UANG DISERAHKAN"
        },
        {
          money_status_id: "R",
          money_status_name: "UANG DIKEMBALIKAN"
        }
      ],
      data_cashbank_type_combobox: [
        {
          cashbank_type_id: "KBK",
          cashbank_type_name: "KAS BESAR"
        },
        {
          cashbank_type_id: "KKK",
          cashbank_type_name: "KAS KECIL"
        }
      ],
      data_field_activities_date: {
        date_submission: new Date().toISOString().substr(0, 10),
        date: new Date().toISOString().substr(0, 10)
      },
      data_history_activity_invoices_print: {
        project_id: "",
        project_name: "",
        project_amount: ""
      },
      add_field_activities: {
        workgroup_id: "",
        organizational_unit_id: "",
        work_unit_id: "",
        project_id: "",
        date_submission: new Date().toISOString().substr(0, 10),
        submission_desc: "",
        submission_type: "",
        submission_permission: "",
        transaction_type: "",
        amount: 0,
        payment_status: "",
        money_status: " ",
        sub_organizational_unit: "",
        submission_info: "",
        path_image_order_proof: null,
        path_image_order_proof2: null,
        cashbank_type: ""
      },
      add_field_activities_payment_status: {
        payment_status: ""
      },
      add_activity_invoices: {
        activity_invoice_id: "",
        submission_number: "",
        activity_invoice_name: "",
        activity_invoice_amount: "",
        activity_invoice_desc: "",
        activity_invoice_path_file: ""
      },
      add_field_activities_not_null: {
        date_field_activities: false,
        date_submission: formatDate()
      },
      search_history: {
        amount_range_from: 0,
        amount_range_to: 0
      },
      submitted: false,
      headers: [],
      headers_activity_invoices: [],
      headers_history_activity_invoices: [],
      editedIndex: -1,
      deletedIndex: -1,
      editedItem: {},
      upload_bill_file: {},
      editedItemActivityInvoices: {},
      editedItemPaymentStatus: {},
      editedSubmissionInfo: {},
      deletedItem: {},
      deletedItemActivityInvoices: {},
      defaultItem: {},
      isFirstLoad: true,
      options: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false
      },
      htmlToPdfAllOptions: {
        margin: 0.1,
        filename: `RIWAYAT KEGIATAN LAPANGAN.pdf`,
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait"
        }
      },
      label: "",
      placeholder: "",
      readonly: false,
      disabled: false,
      outlined: false,
      clearable: false,
      valueWhenIsEmpty: "",
      option: {
        locale: "en-US",
        prefix: "",
        suffix: "",
        length: 20,
        precision: 0
      },
      date_submission: "",
      date: "",
      user_role: "",
      seen: false,
      seenHistory: false,
      seenButtonPrint: false,
      submissionNumber: "",
      organizationalUnitId: "",
      workUnitId: "",
      submissionAmount: "",
      moneyStatus: 0,
      cashSubmissionStatus: 0,
      checkingStatus: "",
      approvalStatus: "",
      dateSubmission: "",
      cashbankType: "",
      workgroupId: "",
      projectId: "",
      submissionDesc: "",
      submissionAmountToVoucher: "",
      createdBy: "",
      user_id: "",
      user_fullname: "",
      email: "",
      count_escrow_notification: 0,
      count_bank_guarantee_notification: 0,
      count_submission_form_finance_notification: 0,
      paymentStatus: "",
      loadingTable: true
    }),
  
    computed: {
      ...mapGetters([
        "layoutConfig",
        "getClasses",
        "currentUserPersonalInfo",
        "currentUserPhoto"
      ]),
  
      headerMenuEnabled() {
        return !!this.layoutConfig("header.menu.self.display");
      },
  
      headerClasses() {
        const classes = this.getClasses("header");
        if (typeof classes !== "undefined") {
          return classes.join(" ");
        }
        return null;
      },
  
      headerMenuClasses() {
        const classes = this.getClasses("header_menu");
        if (typeof classes !== "undefined") {
          return classes.join(" ");
        }
        return null;
      },
  
      totalNotification: function() {
        let countEscrowNotification = this.count_escrow_notification;
        let countBankGuaranteeNotification = this
          .count_bank_guarantee_notification;
        let countSubmissionFormFinanceNotification = this
          .count_submission_form_finance_notification;
        let totalNotification = 0;
  
        totalNotification += parseInt(
          countEscrowNotification +
            countBankGuaranteeNotification +
            countSubmissionFormFinanceNotification
        );
  
        return totalNotification;
      },
  
      config() {
        return this.layoutConfig();
      },
  
      formTitle() {
        let result = "";
  
        if (this.editedIndex === -1) {
          result = "BUAT FORM PENGAJUAN";
        } else if (this.editedIndex === -2) {
          result = "BUAT KEGIATAN";
        } else if (this.editedIndex === -3) {
          result = "UBAH KEGIATAN";
        } else if (this.editedIndex === -4) {
          result = "BERIKAN INFORMASI";
        } else if (this.editedIndex === -5) {
          result = "BUKTI PERINTAH";
        } else {
          result = "UBAH FORM PENGAJUAN";
        }
  
        return result;
      },
  
      formInput() {
        let result = "";
  
        if (this.editedIndex === -1) {
          result = this.add_field_activities;
        } else if (this.editedIndex === -2) {
          result = this.add_activity_invoices;
        } else if (this.editedIndex === -3) {
          result = this.editedItemActivityInvoices;
        } else if (this.editedIndex === -4) {
          result = this.editedSubmissionInfo;
        } else if (this.editedIndex === -6) {
          result = this.upload_bill_file;
        } else {
          result = this.editedItem;
        }
  
        return result;
      },
  
      formInputPaymentStatus() {
        let result = "";
  
        if (this.editedIndex === -1) {
          result = this.add_field_activities_payment_status;
        } else {
          result = this.editedItemPaymentStatus;
        }
  
        return result;
      },
  
      formInputNotClear() {
        let result = "";
  
        if (this.editedIndex === -1) {
          result = this.add_field_activities_not_null;
        } else {
          result = this.editedItem;
        }
  
        return result;
      },
  
      formSubmit() {
        let result = "";
  
        if (this.editedIndex === -1) {
          result = this.createDataFieldActivities;
        } else if (this.editedIndex === -2) {
          result = this.createDataActivityInvoices;
        } else if (this.editedIndex === -3) {
          result = this.updateDataActivityInvoices;
        } else if (this.editedIndex === -4) {
          result = this.updateDataFieldActivitiesInfo;
        } else if (this.editedIndex === -6) {
          result = this.uploadBill;
        } else {
          result = this.updateDataFieldActivities;
        }
  
        return result;
      },
  
      computedDate() {
        return this.formatDate(this.date_submission);
      },
  
      computedDateModified() {
        return this.formatDate(this.date);
      }
    },
  
    watch: {
      dialog(val) {
        val || this.close();
      },
  
      dialogCreateActivityInvoices(val) {
        val || this.closeActivityInvoices();
      },
  
      dialogSubmissionInfo(val) {
        val || this.closeSubmissionInfo();
      },
  
      dialogDelete(val) {
        val || this.closeDelete();
      },
  
      dialogDeleteActivityInvoices(val) {
        val || this.closeDeleteActivityInvoices();
      },
  
      dialogMoneyStatus(val) {
        val || this.closeMoneyStatus();
      },
  
      options: {
        async handler(val) {
          val;
          if (!this.isFirstLoad) await this.dataDoMasuk();
          this.loading = false;
        },
        deep: true
      },
  
      date_submission() {
        this.formInputNotClear.date_submission = this.formatDate(
          this.date_submission
        );
      },
  
      date() {
        this.formInputNotClear.date = this.formatDate(this.date);
      }
    },
  
    created() {
      const self = this;
      this.data_activity_invoices.map(item => {
        if (item.paid_status === 0 ? false : true) self.disabledCount += 1;
      });
  
      this.initHeders();
      this.initHedersActivityInvoices();
      this.initHedersHistoryActivityInvoices();
      this.initSubOrganizationalUnitCombobox();
    },
  
    mounted() {
      // Init Desktop & Mobile Headers
      KTLayoutHeader.init("kt_header", "kt_header_mobile");
  
      // Init Header Menu
      KTLayoutHeaderMenu.init(
        this.$refs["kt_header_menu"],
        this.$refs["kt_header_menu_wrapper"]
      );
  
      const headerRef = this.$refs["kt_header"];
  
      headerRef.querySelectorAll("a[class='menu-link']").forEach(item => {
        item.addEventListener("click", () => {
          KTLayoutHeaderMenu.getOffcanvas().hide();
        });
      });
  
      this.load();
  
      // Init Quick User Panel
      KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  
      this.user_id = localStorage.getLocalStorage("UID");
      this.user_fullname = localStorage.getLocalStorage("User_Fullname");
      this.email = localStorage.getLocalStorage("User_Email");
      this.user_role = localStorage.getLocalStorage("User_Role");
  
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Form Pengajuan" }]);
    },
  
    methods: {
      onLogout() {
        this.$store
          .dispatch(LOGOUT)
          .then(() => this.$router.push({ name: "login" }));
      },
  
      closeOffcanvas() {
        new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
      },
  
      createImage(file) {
        let reader = new FileReader();
  
        reader.onload = e => {
          this.add_field_activities.path_image_order_proof2 = e.target.result;
          this.count = this.add_field_activities.path_image_order_proof2.length;
        };
  
        reader.readAsDataURL(file);
      },
  
      onImageChange(file) {
        this.createImage(file);
      },
  
      row_bg_color(item) {
        if (item.money_status == "HO") {
          return "bg-green";
        } else if (item.money_status == "R") {
          return "bg-red";
        } else if (item.money_status == "S") {
          return "bg-yellow";
        } else if (item.money_status == "C") {
          return "bg-blue";
        } else if (item.money_status == "A") {
          return "bg-orange";
        }
      },
  
      selectAllToggle(props) {
        if (
          this.selected.length !=
          this.data_activity_invoices.length - this.disabledCount
        ) {
          this.selected = [];
          const self = this;
          props.items.forEach(item => {
            if (!(item.paid_status === 0) ? false : true) {
              self.selected.push(item);
            }
          });
        } else this.selected = [];
      },
  
      enterSelect(values) {
        let data_activity_invoices = values.map(function(value) {
          return value.activity_invoice_id;
        });
  
        this.add_activity_invoices.activity_invoice_id = data_activity_invoices;
      },
  
      getHeaders() {
        return [
          {
            text: "Id",
            sortable: false,
            value: "submission_number",
            align: " d-none"
          },
          { text: "NO", value: "number", width: "80px" },
          {
            text: "NO PENGAJUAN",
            sortable: true,
            value: "submission_number",
            width: "150px"
          },
          {
            text: "PERUSAHAAN",
            sortable: true,
            value: "workgroup_name",
            width: "140px"
          },
          {
            text: "TGL TRANSFER",
            sortable: true,
            value: "date_submission",
            width: "150px"
          },
          {
            text: "UNIT MATRA",
            sortable: true,
            value: "organizational_unit_name",
            width: "160px"
          },
          {
            text: "SUB UNIT MATRA",
            sortable: true,
            value: "sub_organizational_unit",
            width: "190px"
          },
          {
            text: "SATUAN/NON SATUAN KERJA",
            sortable: true,
            value: "work_unit_name",
            width: "220px"
          },
          {
            text: "PROYEK",
            sortable: true,
            value: "project_name",
            width: "130px"
          },
          {
            text: "DESKRIPSI",
            sortable: true,
            value: "submission_desc",
            width: "130px"
          },
          {
            text: "NOMINAL",
            sortable: true,
            value: "amount",
            width: "130px"
          },
          // {
          //   text: "Status Pembayaran",
          //   sortable: true,
          //   value: "payment_status",
          //   width: "190px"
          // },
          {
            text: "STATUS UANG",
            sortable: true,
            value: "money_status",
            width: "140px"
          },
          {
            text: "BUKTI PERINTAH",
            sortable: true,
            value: "order_proof",
            width: "150px"
          },
          {
            text: "DIAJUKAN OLEH",
            sortable: true,
            value: "created_by",
            width: "150px"
          },
          {
            text: "TGL DIAJUKAN",
            sortable: true,
            value: "date_created",
            width: "170px"
          },
          {
            text: "DICEK OLEH",
            sortable: true,
            value: "checking_by",
            width: "130px"
          },
          {
            text: "TGL DICEK",
            sortable: true,
            value: "date_checking",
            width: "150px"
          },
          {
            text: "DISETUJUI OLEH",
            sortable: true,
            value: "approval_by",
            width: "150px"
          },
          {
            text: "TGL DISETUJUI",
            sortable: true,
            value: "date_approval",
            width: "170px"
          },
          {
            text: "INFO",
            sortable: true,
            value: "submission_info",
            width: "100px",
            align: localStorage.getLocalStorage("User_Role").toLowerCase() != "atasan" ? "" : " d-none"
          },
          {
            text: "EDIT",
            value: "aksi",
            sortable: false,
            align: "center",
            width: "280px"
          }
        ];
      },
  
      getHeadersActivityInvoices() {
        return [
          {
            text: "Id",
            sortable: false,
            value: "activity_invoice_id",
            align: " d-none"
          },
          { text: "NO", value: "number", width: "80px" },
          {
            text: "NAMA",
            sortable: true,
            value: "activity_invoice_name"
          },
          {
            text: "NOMINAL",
            sortable: true,
            value: "activity_invoice_amount"
          },
          {
            text: "KETERANGAN",
            sortable: true,
            value: "activity_invoice_desc"
          },
          {
            text: "BON",
            sortable: true,
            value: "bill_file"
          },
          {
            text: "EDIT",
            value: "aksi",
            sortable: false,
            width: "110px"
          }
        ];
      },
  
      getHeadersHistoryActivityInvoices() {
        return [
          {
            text: "Id",
            sortable: false,
            value: "activity_invoice_id",
            align: " d-none"
          },
          { text: "NO", value: "number", width: "80px" },
          {
            text: "PROYEK",
            sortable: true,
            value: "project_name"
          },
          {
            text: "NOMINAL PROYEK",
            sortable: true,
            value: "project_amount"
          },
          {
            text: "JABATAN",
            sortable: true,
            value: "position_name"
          },
          {
            text: "NAMA",
            sortable: true,
            value: "activity_invoice_name"
          },
          {
            text: "NOMINAL",
            sortable: true,
            value: "amount"
          }
        ];
      },
  
      getSubOrganizationalUnitCombobox() {
        if (localStorage.getLocalStorage("User_Role") == "atasan") {
          return [
            {
              sub_organizational_unit_id: "HO",
              sub_organizational_unit_name: "HO"
            },
            {
              sub_organizational_unit_id: "Satker",
              sub_organizational_unit_name: "SATKER"
            },
            {
              sub_organizational_unit_id: "Non-Satker",
              sub_organizational_unit_name: "NON-SATKER"
            }
          ];
        } else {
          return [
            {
              sub_organizational_unit_id: "Satker",
              sub_organizational_unit_name: "SATKER"
            },
            {
              sub_organizational_unit_id: "Non-Satker",
              sub_organizational_unit_name: "NON-SATKER"
            }
          ];
        }
      },
  
      loadDate: function() {
        this.date_submission = new Date().toISOString().substr(0, 10);
        this.formInputNotClear.date_submission = this.formatDate(
          new Date().toISOString().substr(0, 10)
        );
      },
  
      loadDateModified: function() {
        this.date = new Date().toISOString().substr(0, 10);
        this.formInputNotClear.date = this.formatDate(
          new Date().toISOString().substr(0, 10)
        );
      },
  
      formatDate(date) {
        if (!date) return null;
  
        var arr = date.split("-"),
          year = arr[0],
          month = arr[1],
          day = arr[2];
        return `${day}-${month}-${year}`;
      },
  
      parseDate: function(date) {
        if (!date) return null;
  
        var arr = date.split("/"),
          day = arr[0],
          month = arr[1],
          year = arr[2];
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      },
  
      onChangeDate(item) {
        this.$refs.date_field_activities.save(item);
        this.data_field_activities_date.date_submission = item;
      },
  
      onChangeSearchHistoryAmountFrom(item) {
        this.search_history.amount_range_from = item;
      },
  
      onChangeSearchHistoryAmountTo(item) {
        this.search_history.amount_range_to = item;
      },
  
      createDataFieldActivities() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "C",
            Token: "C",
            UID: localStorage.getLocalStorage("UID"),
            Route: "DEFAULT",
            workgroup_id: this.add_field_activities.workgroup_id,
            organizational_unit_id: this.add_field_activities.organizational_unit_id,
            sub_organizational_unit: this.add_field_activities.sub_organizational_unit,
            work_unit_id: this.add_field_activities.work_unit_id,
            project_id: this.add_field_activities.project_id,
            date_submission: this.data_field_activities_date.date_submission,
            submission_desc: this.add_field_activities.submission_desc,
            submission_type: "KKK",
            submission_permission: "FA",
            cashbank_type: this.add_field_activities.cashbank_type,
            transaction_type: this.add_field_activities.transaction_type,
            amount: this.add_field_activities.amount,
            // payment_status:
            //   this.add_field_activities_payment_status.payment_status != "" ? this.add_field_activities_payment_status.payment_status : this.add_field_activities.payment_status,
            destination: "order-proof",
            path_image_order_proof: this.add_field_activities.path_image_order_proof,
            path_image_project: "http://emitra.asiatechintiraya.id/images/submission-form/.",
            path_image_bank_guarantee: "http://emitra.asiatechintiraya.id/images/bank-guarantee/.",
            created_by: localStorage.getLocalStorage("User_Fullname"),
            status_cashing: 0,
            status: 1
          };
  
          var postdata = new FormData();
  
          for (var key in mydata) {
            postdata.append(key, mydata[key]);
          }
  
          let contentType = `multipart/form-data; boundary=${postdata._boundary}`;
  
          Services.PostData(
            ApiService,
            "Transaction/SubmissionForm",
            postdata,
            contentType,
            response => {
              resolve(response.data);
              if (response.status == 1000) {
                Swal.fire({
                  title: "",
                  text: "BERHASIL MENAMBAH DATA",
                  icon: "success",
                  heightAuto: true,
                  timer: 1500
                });
              } else {
                Swal.fire({
                  title: "",
                  text: response.message,
                  icon: "info",
                  heightAuto: true,
                  timer: 1500
                });
              }
              this.create_data_field_activities = response.data;
              this.submitted = true;
              this.save("add_field_activities");
              this.loadDataFieldActivities();
            },
            err => {
              err;
            }
          );
        });
      },
  
      createDataActivityInvoices() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "C",
            Token: "C",
            UID: localStorage.getLocalStorage("UID"),
            Route: "DEFAULT",
            submission_number: this.submissionNumber,
            activity_invoice_name: this.add_activity_invoices.activity_invoice_name,
            activity_invoice_amount: this.add_activity_invoices.activity_invoice_amount,
            activity_invoice_desc: this.add_activity_invoices.activity_invoice_desc,
            created_by: localStorage.getLocalStorage("User_Fullname"),
            approval_status: this.approvalStatus
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Master/ActivityInvoices",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              if (response.status == 1000) {
                Swal.fire({
                  title: "",
                  text: "BERHASIL MEMPERBAHARUI DATA",
                  icon: "success",
                  heightAuto: true,
                  timer: 1500
                });
              } else {
                Swal.fire({
                  title: "",
                  text: response.message,
                  icon: "info",
                  heightAuto: true,
                  timer: 1500
                });
              }
              this.create_data_activity_invoices = response.data;
              this.submitted = true;
              this.save("add_activity_invoices");
              this.loadDataActivityInvoices();
            },
            err => {
              err;
            }
          );
        });
      },
  
      updateDataFieldActivities() {
        return new Promise(resolve => {  
          var mydata = {
            Trigger: "U",
            Token: "U",
            UID: localStorage.getLocalStorage("UID"),
            Route: "DEFAULT",
            submission_number: this.editedItem.submission_number,
            workgroup_id: this.editedItem.workgroup_id,
            organizational_unit_id: this.editedItem.organizational_unit_id,
            sub_organizational_unit: this.editedItem.sub_organizational_unit,
            work_unit_id: this.editedItem.work_unit_id,
            project_id: this.editedItem.project_id,
            date_submission: this.data_field_activities_date.date_submission,
            submission_desc: this.editedItem.submission_desc,
            submission_type: "KKK",
            submission_permission: "FA",
            submission_financing: 0,
            transaction_type: this.editedItem.transaction_type,
            amount: this.editedItem.amount,
            destination: "order-proof",
            path_image_order_proof: this.editedItem.path_image_order_proof,
            path_image_project:
              this.editedItem.path_image_project != "."
                ? this.editedItem.path_image_project != ""
                  ? this.editedItem.path_image_project
                  : "http://emitra.asiatechintiraya.id/images/submission-form/."
                : "http://emitra.asiatechintiraya.id/images/submission-form/.",
            path_image_bank_guarantee:
              this.editedItem.path_image_bank_guarantee != "."
                ? this.editedItem.path_image_bank_guarantee != ""
                  ? this.editedItem.path_image_bank_guarantee
                  : "http://emitra.asiatechintiraya.id/images/bank-guarantee/."
                : "http://emitra.asiatechintiraya.id/images/bank-guarantee/.",
            modified_by: localStorage.getLocalStorage("User_Fullname"),
            date_modified: this.data_field_activities_date.date,
            checking_status: this.editedItem.checking_status,
            approval_status: this.editedItem.approval_status,
            allocation_status: this.editedItem.allocation_status,
            status_cashing: 0,
            status: 1
          };
  
          var postdata = new FormData();
  
          for (var key in mydata) {
            postdata.append(key, mydata[key]);
          }
  
          let contentType = `multipart/form-data; boundary=${postdata._boundary}`;
  
          Services.PostData(
            ApiService,
            "Transaction/SubmissionForm",
            postdata,
            contentType,
            response => {
              resolve(response.data);
              if (response.status == 1000) {
                Swal.fire({
                  title: "",
                  text: "BERHASIL MEMPERBAHARUI DATA",
                  icon: "success",
                  heightAuto: true,
                  timer: 1500
                });
              } else {
                Swal.fire({
                  title: "",
                  text: response.message,
                  icon: "info",
                  heightAuto: true,
                  timer: 1500
                });
              }
              this.update_data_field_activities = response.data;
              this.submitted = true;
              this.save("edit_field_activities");
              this.loadDataFieldActivities();
            },
            err => {
              err;
            }
          );
        });
      },
  
      updateDataActivityInvoices() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "U",
            Token: "U",
            UID: localStorage.getLocalStorage("UID"),
            Route: "DEFAULT",
            activity_invoice_id: this.editedItemActivityInvoices.activity_invoice_id,
            submission_number: this.submissionNumber,
            activity_invoice_name: this.editedItemActivityInvoices.activity_invoice_name,
            activity_invoice_amount: this.editedItemActivityInvoices.activity_invoice_amount,
            activity_invoice_desc: this.editedItemActivityInvoices.activity_invoice_desc,
            modified_by: localStorage.getLocalStorage("User_Fullname"),
            date_modified: this.data_field_activities_date.date
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Master/ActivityInvoices",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              if (response.status == 1000) {
                Swal.fire({
                  title: "",
                  text: "BERHASIL MEMPERBAHARUI DATA",
                  icon: "success",
                  heightAuto: true,
                  timer: 1500
                });
              } else {
                Swal.fire({
                  title: "",
                  text: response.message,
                  icon: "info",
                  heightAuto: true,
                  timer: 1500
                });
              }
              this.update_data_activity_invoices = response.data;
              this.submitted = true;
              this.save("edit_activity_invoices");
              this.loadDataActivityInvoices();
            },
            err => {
              err;
            }
          );
        });
      },

      updateDataFieldActivitiesCashSubmissionStatus(item) {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "U",
            Token: "U",
            UID: localStorage.getLocalStorage("UID"),
            Route: "CASH_SUBMISSION_STATUS",
            submission_number: item.submission_number,
            cash_submission_status: 1
          };

          let contentType = `application/x-www-form-urlencoded`;

          const qs = require("qs");

          Services.PostData(
            ApiService,
            "Transaction/SubmissionForm",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              if (response.status == 1000) {
                Swal.fire({
                  title: "",
                  text: "BERHASIL MEMPERBAHARUI DATA",
                  icon: "success",
                  heightAuto: true,
                  timer: 1500
                });
              } else {
                Swal.fire({
                  title: "",
                  text: response.message,
                  icon: "info",
                  heightAuto: true,
                  timer: 1500
                });
              }
              this.update_data_field_activities = response.data;
              this.submitted = true;
              this.save("edit_field_activities");
              this.loadDataFieldActivities();
            },
            err => {
              err;
            }
          );
        });
      },

      updateDataFieldActivitiesApproval(item) {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "U",
            Token: "U",
            UID: localStorage.getLocalStorage("UID"),
            Route: "APPROVAL_FIELD_ACTIVITIES",
            submission_number: item.submission_number,
            workgroup_id: item.workgroup_id,
            approval_by: localStorage.getLocalStorage("User_Fullname"),
            cashbank_permission: "O",
            transaction_type: "FA",
            submission_type: item.submission_type,
            cashbank_type: item.submission_type,
            money_status: "A",
            approval_status: 1
          };

          let contentType = `application/x-www-form-urlencoded`;

          const qs = require("qs");

          Services.PostData(
            ApiService,
            "Transaction/SubmissionForm",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              if (response.status == 1000) {
                Swal.fire({
                  title: "",
                  text: "BERHASIL MEMPERBAHARUI DATA",
                  icon: "success",
                  heightAuto: true,
                  timer: 1500
                });
              } else {
                Swal.fire({
                  title: "",
                  text: response.message,
                  icon: "info",
                  heightAuto: true,
                  timer: 1500
                });
              }
              this.update_data_field_activities = response.data;
              this.submitted = true;
              this.save("edit_field_activities");
              this.loadDataFieldActivities();
            },
            err => {
              err;
            }
          );
        });
      },

      uploadBill() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "C",
            Token: "C",
            UID: localStorage.getLocalStorage("UID"),
            Route: "DEFAULT_ACTIVITY_INVOICES",
            source_table_id: this.upload_bill_file.activity_invoice_id,
            destination: "bill"
          };

          var postdata = new FormData();

          for (var key in mydata) {
            postdata.append(key, mydata[key]);
          }

          for (const file of this.upload_bill_file.path_file_name) {
            postdata.append("path_file_name", file);
          }

          let contentType = `muntipart/forn-data; boundary=${postdata._boundary}`;

          Services.PostData(
            ApiService,
            "Master/PathFiles",
            postdata,
            contentType,
            response => {
              resolve(response.data);
              if (response.status == 1000) {
                Swal.fire({
                  title: "",
                  text: "BERHASIL MEMPERBAHARUI DATA",
                  icon: "success",
                  heightAuto: true,
                  timer: 1500
                });
              } else {
                Swal.fire({
                  title: "",
                  text: response.message,
                  icon: "info",
                  heightAuto: true,
                  timer: 1500
                });
              }
              this.update_data_activity_invoices = response.data;
              this.submitted = true;
              this.save("edit_activity_invoices");
              this.dialogUploadBill = false;
              this.loadDataActivityInvoices();
            },
            err => {
              err;
            }
          );
        });
      },
  
      deleteDataFieldActivities() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "D",
            Token: "D",
            UID: localStorage.getLocalStorage("UID"),
            Route: "DEFAULT",
            submission_number: this.deletedItem.submission_number
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Transaction/SubmissionForm",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              if (response.status == 1000) {
                Swal.fire({
                  title: "",
                  text: "BERHASIL MENGHAPUS DATA",
                  icon: "success",
                  heightAuto: true,
                  timer: 1500
                });
              } else {
                Swal.fire({
                  title: "",
                  text: response.message,
                  icon: "info",
                  heightAuto: true,
                  timer: 1500
                });
              }
              this.delete_data_field_activities = response.data;
              this.submitted = true;
              this.data_field_activities.splice(this.deletedIndex, 1);
              this.closeDelete();
            },
            err => {
              err;
            }
          );
        });
      },
  
      deleteDataActivityInvoices() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "D",
            Token: "D",
            UID: localStorage.getLocalStorage("UID"),
            Route: "DEFAULT",
            activity_invoice_id: this.deletedItemActivityInvoices.activity_invoice_id,
            submission_number: this.submissionNumber
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Master/ActivityInvoices",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              if (response.status == 1000) {
                Swal.fire({
                  title: "",
                  text: "BERHASIL MENGHAPUS DATA",
                  icon: "success",
                  heightAuto: true,
                  timer: 1500
                });
              } else {
                Swal.fire({
                  title: "",
                  text: response.message,
                  icon: "info",
                  heightAuto: true,
                  timer: 1500
                });
              }
              this.delete_data_activity_invoices = response.data;
              this.submitted = true;
              this.data_activity_invoices.splice(this.deletedIndex, 1);
              this.closeDeleteActivityInvoices();
            },
            err => {
              err;
            }
          );
        });
      },

      deleteDataBill(item) {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "D",
            Token: "D",
            UID: localStorage.getLocalStorage("UID"),
            Route: "DEFAULT",
            path_file_id: item
          };

          let contentType = `application/x-www-form-urlencoded`;

          const qs = require("qs");

          Services.PostData(
            ApiService,
            "Master/PathFiles",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              if (response.status == 1000) {
                Swal.fire({
                  title: "",
                  text: "BERHASIL MENGHAPUS DATA",
                  icon: "success",
                  heightAuto: true,
                  timer: 1500
                });
              } else {
                Swal.fire({
                  title: "",
                  text: response.message,
                  icon: "info",
                  heightAuto: true,
                  timer: 1500
                });
              }
              this.delete_data_files = response.data;
              this.submitted = true;
              this.data_files.splice(this.deletedIndex, 1);
              this.closeDelete();
            },
            err => {
              err;
            }
          );
        });
      },
  
      dataFieldActivities() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            Route: "DEFAULT_ACTIVITY_INVOICES",
            submission_type: "KKK",
            submission_permission: "FA"
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Transaction/SubmissionForm",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              this.data_field_activities = response.data;
              this.loadingTable = false;
            },
            err => {
              err;
            }
          );
        });
      },
  
      dataActivityInvoices() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            Route: "DEFAULT",
            submission_number: this.submissionNumber
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Master/ActivityInvoices",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              this.data_activity_invoices = response.data;
              this.loadingTable = false;
            },
            err => {
              err;
            }
          );
        });
      },
  
      seenDataFile(item) {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            Route: "DEFAULT_ALL_FILES",
            submission_number: item.submission_number
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Transaction/SubmissionForm",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              this.data_files = response.data;
            },
            err => {
              err;
            }
          );
        });
      },

      seenBill(item) {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            Route: "DEFAULT",
            source_table_id: item.activity_invoice_id
          };

          let contentType = `application/x-www-form-urlencoded`;

          const qs = require("qs");

          Services.PostData(
            ApiService,
            "Master/PathFiles",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              this.data_files = response.data;
            },
            err => {
              err;
            }
          );
        });
      },
  
      dataWorkgroup_Combobox() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            Route: "COMBOBOX"
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Master/Workgroup",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              this.data_workgroup_combobox = response.data;
            },
            err => {
              err;
            }
          );
        });
      },
  
      dataOrganizationalUnits_Combobox() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            Route: "COMBOBOX"
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Master/OrganizationalUnits",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              this.data_organizational_units_combobox = response.data;
            },
            err => {
              err;
            }
          );
        });
      },
  
      dataWorkUnits_Combobox() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            sub_organizational_unit: this.itemSubOrganizationalUnit,
            Route: "COMBOBOX",
            organizational_unit_id: this.formInput.organizational_unit_id
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Master/WorkUnits",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              this.data_work_units_combobox = response.data;
            },
            err => {
              err;
            }
          );
        });
      },
  
      dataProject_Combobox() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            Route: "COMBOBOX",
            workgroup_id: this.formInput.workgroup_id
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Master/Project",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              this.data_project_combobox = response.data;
            },
            err => {
              err;
            }
          );
        });
      },
  
      dataNotification(item) {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            Route: "NOTIFICATION_MOBILE",
            submission_number: item.submission_number,
            submission_permission: item.submission_permission
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Transaction/SubmissionForm",
            qs.stringify(mydata),
            contentType,
            response => {
              let tokenId = '';
              resolve(response.data);
              this.data_token = response.data;
              for (let i = 0; i < this.data_token.length; i++) {
                tokenId = this.data_token[i];
              }
              this.sendNotification(tokenId);
            },
            err => {
              err;
            }
          );
        });
      },
  
      sendNotification(item) {
        let tokenId = '';
        tokenId = item.token_id.split(',');
        const auth_token = 'key=AAAAMQjsjRo:APA91bFy3l1_o6QO94xjOybDBkgQiTq4tpVLYBfcIPDWmVSVjKqwgFgrawhTHOmLZCdqEcHcVtT8KkIHN1y-4g7XGEGVEFQWZ1I92-HVlwI5Y5_121h-7BCBMsJCwgNCAPSriudLOGIG';
  
        const instance = axios.create({
          baseURL: 'https://fcm.googleapis.com/',
          headers: {
            'Authorization': auth_token,
            'Content-Type': 'application/json'
          }
        });
  
        const data = {
          "registration_ids": tokenId,
          "priority": "high",
          "soundName": "default",
          "notification": {
            "title": "Approval",
            "body": `Approval data dengan No Pengajuan ${item.submission_number}`
          }
        };
  
        instance.post('fcm/send', data)
          .then(response => {
            if (response.status == 200) {
              Swal.fire({
                title: "",
                text: "BERHASIL MENGIRIM NOTIFIKASI",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
          })
          .catch(error => {
            console.log(error);
          });
      },
  
      dataEscrowPaymentAccepted() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            Route: "ESCROW_PAYMENT_ACCEPTED",
            payment_accepted: "E",
            status_receipt: 1,
            status_escrow_accepted: 0
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Transaction/BankTransaction",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              this.data_escrow_notification = response.data;
              this.count_escrow_notification =
                this.user_role.toLowerCase() === "atasan keuangan"
                  ? response.data.length
                  : 0;
            },
            err => {
              err;
            }
          );
        });
      },
  
      dataBankGuaranteeNotification() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            Route: "NOTIFICATION_BANK_GUARANTEE",
            status_cashing: 0
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Transaction/BankGuarantee",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              this.data_bank_guarantee_notification = response.data;
              this.count_bank_guarantee_notification =
                this.user_role.toLowerCase() === "atasan"
                  ? response.data.length
                  : 0;
            },
            err => {
              err;
            }
          );
        });
      },
  
      dataSubmissionFormNotification() {
        return new Promise(resolve => {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            Route: "NOTIFICATION_FOR_BANK_GUARANTEE",
            submission_type: "BGOI",
            status_cashing: 0
          };
  
          let contentType = `application/x-www-form-urlencoded`;
  
          const qs = require("qs");
  
          Services.PostData(
            ApiService,
            "Transaction/SubmissionForm",
            qs.stringify(mydata),
            contentType,
            response => {
              resolve(response.data);
              this.data_submission_form_finance_notification = response.data;
              this.count_submission_form_finance_notification =
                this.user_role.toLowerCase() === "atasan"
                  ? response.data.length
                  : 0;
            },
            err => {
              err;
            }
          );
        });
      },
  
      async load() {
        Promise.all([
          await this.dataEscrowPaymentAccepted(),
          await this.dataBankGuaranteeNotification(),
          await this.dataSubmissionFormNotification(),
          await this.dataFieldActivities()
        ]).then(function(results) {
          results;
        });
      },
  
      async loadDataFieldActivities() {
        Promise.all([await this.dataFieldActivities()]).then(function(results) {
          results;
        });
      },
  
      async loadCombobox() {
        Promise.all([
          await this.dataWorkgroup_Combobox(),
          await this.dataOrganizationalUnits_Combobox(),
          await this.dataWorkUnits_Combobox(),
          await this.dataProject_Combobox()
        ]).then(function(results) {
          results;
        });
      },
  
      async loadDataActivityInvoices() {
        Promise.all([await this.dataActivityInvoices()]).then(function(results) {
          results;
        });
      },
  
      async asyncCreateDataActivityInvoices() {
        Promise.all([await this.createDataActivityInvoices()]).then(function(results) {
          results;
        });
      },
  
      async asyncUpdateDataFieldActivitiesStatusChecking() {
        Promise.all([await this.updateDataFieldActivitiesStatusChecking()]).then(
          function(results) {
            results;
          }
        );
      },
  
      async asyncUpdateDataActivityInvoicesStatusApproval() {
        Promise.all([
          await this.updateDataActivityInvoicesStatusApproval(),
          await this.createDataVoucherOperational()
        ]).then(function(results) {
          results;
        });
      },
  
      async asyncUpdateDataFieldActivitiesMoneyStatus() {
        Promise.all([
          await this.updateDataFieldActivitiesMoneyStatus(),
          await this.updateDataFieldActivitiesAmount()
        ]).then(function(results) {
          results;
        });
      },
  
      async asyncUpdateDataFieldActivitiesAllocation() {
        Promise.all([await this.updateDataFieldActivitiesAllocation()]).then(
          function(results) {
            results;
          }
        );
      },
  
      activityInvoicesSelect(item) {
        console.log(item.approval_status);
        this.submissionNumber = item.submission_number;
        this.organizationalUnitId = item.organizational_unit_id;
        this.workUnitId = item.work_unit_id;
        this.data_history_activity_invoices_print.project_name = item.project_name;
        this.data_history_activity_invoices_print.project_amount = item.project_amount;
        this.submissionAmount = this.amount;
        this.cashSubmissionStatus = item.cash_submission_status;
        this.checkingStatus = item.checking_status;
        this.approvalStatus = item.approval_status;
  
        this.dateSubmission = item.date_submission;
        this.workgroupId = item.workgroup_id;
        this.projectId = item.project_id;
        this.submissionDesc = item.submission_desc;
        this.cashbankType = item.cashbank_type;
        this.submissionAmountToVoucher = item.amount;
        this.createdBy = item.created_by;
  
        this.dataActivityInvoices();
        this.dialogShowActivityInvoicesTable = true;
      },
  
      showHistoryActivityInvoices(item) {
        this.seenHistory = item;
        this.seenButtonPrint = false;
        this.dialogShowActivityInvoicesTable = item;
  
        if (!item) {
          this.search_history.amount_range_from = 0;
          this.search_history.amount_range_to = 0;
        }
      },

      uploadFileBill(item) {
        this.editedIndex = -6;
        this.upload_bill_file = Object.assign({}, item);
        this.dialogUploadBill = true;
      },

      seenFileBill(item) {
        this.editedIndex = -7;
        this.seenBill(item);
        this.dialogSeenBill = true;
      },
  
      seenFile(item) {
        this.editedIndex = -5;
        this.seenDataFile(item);
        this.dialogSeenFile = true;
      },
  
      editItem(item) {
        this.editedIndex = this.data_field_activities.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.editedItemPaymentStatus = Object.assign({}, item);
        this.loadCombobox();
        this.titleFromSubOrganizationalUnit(item.sub_organizational_unit);
        if (
          this.editedItem.payment_status != "100%" &&
          this.editedItem.payment_status != "80%" &&
          this.editedItem.payment_status != "20%"
        ) {
          this.editedItem.payment_status = "Lain-lain";
          this.seen = this.editedItem.payment_status;
        }
        this.dialog = true;
      },

      showDialogcreateDataActivityInvoices() {
        this.editedIndex = -2;
        this.dialogCreateActivityInvoices = true;
      },
  
      editItemActivityInvoices(item) {
        this.editedIndex = -3;
        this.editedItemActivityInvoices = Object.assign({}, item);
        this.dialogCreateActivityInvoices = true;
        this.amountApprovalActivityInvoices = item.amount_approval;
      },
  
      editItemSubmissionInfo(item) {
        this.editedIndex = -4;
        this.editedSubmissionInfo = Object.assign({}, item);
        this.dialogSubmissionInfo = true;
      },
  
      deleteItem(item) {
        this.deletedIndex = this.data_field_activities.indexOf(item);
        this.deletedItem = Object.assign({}, item);
        this.dialogDelete = true;
      },
  
      deleteItemActivityInvoices(item) {
        this.deletedIndex = this.data_activity_invoices.indexOf(item);
        this.deletedItemActivityInvoices = Object.assign({}, item);
        this.dialogDeleteActivityInvoices = true;
      },
  
      deleteItemConfirm() {
        this.data_field_activities.splice(this.deletedIndex, 1);
        this.closeDelete();
      },
  
      deleteItemConfirmActivityInvoices() {
        this.data_activity_invoices.splice(this.deletedIndex, 1);
        this.closeDeleteActivityInvoices();
      },
  
      close() {
        this.dialog = false;
        this.$nextTick(() => {
          this.add_field_activities.workgroup_id = "";
          this.add_field_activities.organizational_unit_id = "";
          this.add_field_activities.sub_organizational_unit = "";
          this.add_field_activities.work_unit_id = "";
          this.add_field_activities.project_id = "";
          this.add_field_activities.submission_desc = "";
          this.add_field_activities.transaction_type = "";
          this.add_field_activities.amount = 0;
          this.add_field_activities.payment_status = "";
          this.add_field_activities.path_image_order_proof = null;
          this.add_field_activities.path_image_order_proof2 = null;
          this.add_field_activities_payment_status.payment_status = "";
          this.seen = this.formInput.payment_status;
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedItemPaymentStatus = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
  
      closeMoneyStatus() {
        this.dialogMoneyStatus = false;
        this.add_field_activities.money_status = "";
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      },
  
      closeActivityInvoices() {
        this.dialogCreateActivityInvoices = false;
        this.$nextTick(() => {
          this.add_activity_invoices.activity_invoice_name = "";
          this.add_activity_invoices.activity_invoice_amount = "";
          this.add_activity_invoices.activity_invoice_desc = "";
          this.editedItemActivityInvoices = Object.assign({}, this.defaultItem);
          this.upload_bill_file = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
  
      closeSubmissionInfo() {
        this.dialogSubmissionInfo = false;
        this.editedSubmissionInfo = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      },
  
      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.deletedItem = Object.assign({}, this.defaultItem);
          this.deletedIndex = -1;
        });
      },
  
      closeDeleteActivityInvoices() {
        this.dialogDeleteActivityInvoices = false;
        this.$nextTick(() => {
          this.deletedItem = Object.assign({}, this.defaultItem);
          this.deletedIndex = -1;
        });
      },
  
      save(formInput) {
        if (formInput == "add_field_activities") {
          if (this.editedIndex > -1) {
            Object.assign(
              this.data_field_activities[this.editedIndex],
              this.add_field_activities
            );
          } else {
            this.data_field_activities.push(this.add_field_activities);
          }
  
          this.close();
        } else if (formInput == "edit_field_activities") {
          if (this.editedIndex > -1) {
            Object.assign(
              this.data_field_activities[this.editedIndex],
              this.editedItem
            );
          } else {
            this.data_field_activities.push(this.editedItem);
          }
  
          this.close();
        } else if (formInput == "edit_field_activities_info") {
          if (this.editedIndex > -1) {
            Object.assign(
              this.data_field_activities[this.editedIndex],
              this.editedItem
            );
          } else {
            this.data_field_activities.push(this.editedItem);
          }
  
          this.closeSubmissionInfo();
        } else if (formInput == "add_activity_invoices") {
          if (this.editedIndex > -1) {
            Object.assign(
              this.data_activity_invoices[this.editedIndex],
              this.add_activity_invoices
            );
          } else {
            this.data_activity_invoices.push(this.add_activity_invoices);
          }
  
          this.closeActivityInvoices();
        } else if (formInput == "edit_activity_invoices") {
          if (this.editedIndex > -1) {
            Object.assign(this.data_activity_invoices[this.editedIndex], this.editedItem);
          } else {
            this.data_activity_invoices.push(this.editedItem);
          }
  
          this.closeActivityInvoices();
        } else if (formInput == "edit_money_status") {
          if (this.editedIndex > -1) {
            Object.assign(
              this.data_field_activities[this.editedIndex],
              this.editedItem
            );
          } else {
            this.data_field_activities.push(this.editedItem);
          }
  
          this.closeMoneyStatus();
        } else if (formInput == "upload_bill_file") {
          if (this.editedIndex > -1) {
            Object.assign(
              this.data_voucher[this.editedIndex],
              this.upload_bill_file
            );
          } else {
            this.data_voucher.push(this.upload_bill_file);
          }

          this.closeActivityInvoices();
        }
      },
  
      generateReportAll() {
        this.data_history_activity_invoices;
        this.$refs.html2PdfAll.generatePdf();
      },
  
      initHeders() {
        this.headers = this.getHeaders();
      },
  
      initHedersActivityInvoices() {
        this.headers_activity_invoices = this.getHeadersActivityInvoices();
      },
  
      initHedersHistoryActivityInvoices() {
        this.headers_history_activity_invoices = this.getHeadersHistoryActivityInvoices();
      },
  
      initSubOrganizationalUnitCombobox() {
        this.data_sub_organizational_unit_combobox = this.getSubOrganizationalUnitCombobox();
      },
  
      seenDetailPaymentStatus() {
        this.seen = this.formInput.payment_status;
      },
  
      titleFromSubOrganizationalUnit(item) {
        if (item == "Satker" || item == "Non-Satker") {
          this.seenComboboxWorkUnit = 1;
        } else {
          this.seenComboboxWorkUnit = 0;
        }
  
        this.title_select = item;
        this.itemSubOrganizationalUnit = item;
      },
  
      itemWorkUnits(item) {
        this.itemWorkUnitId = item;
      },
  
      formatNumber
    }
  };
  </script>
  